/* eslint-disable jsx-a11y/alt-text */
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { useAuth } from '@src/contexts/authContext';
import axios from 'axios';

import * as React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const star = `https://statics.work-wiz.com/static-files/ww-locum/gamification/badge/locum.badge.star.png`;
const ace = `https://statics.work-wiz.com/static-files/ww-locum/gamification/badge/locum.badge.ace.png`;
const pro = `https://statics.work-wiz.com/static-files/ww-locum/gamification/badge/locum.badge.pro.png`;
const champ = `https://statics.work-wiz.com/static-files/ww-locum/gamification/badge/locum.badge.champ.png`;

const url = `${process.env.REACT_APP_API_URL}/admin/v3/users`;

export default function BadgeCustomization() {
  const { auth } = useAuth();
  const { userId } = useParams();
  const [badge, setBadge] = React.useState({
    show_locum_badge_level: false,
    locum_badge_level: '',
  });

  React.useEffect(() => {
    if (auth.idToken) {
      axios
        .get(`${url}/${userId}/badges`, {
          headers: {
            Authorization: `Bearer ${auth.idToken}`,
          },
        })
        .then((response) => {
          setBadge(response.data.response.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleUpdateBadge = () => {
    const formData = {
      show_locum_badge_level: badge.show_locum_badge_level,
      locum_badge_level: badge.locum_badge_level,
    };

    axios
      .post(`${url}/${userId}/badges`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.idToken}`,
        },
      })
      .then((response) => {
        console.log('Badge updated successfully:', response);

        toast.success('Badge updated successfully', {
          position: 'top-center',
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.error('Error:', error.message);
        toast.error(`${error.response.data.message}`, {
          position: 'top-center',
          autoClose: 2000,
        });
      });
  };

  return (
    <Box margin={2} width='100%' sx={{ borderRadius: 4 }}>
      <Box
        width='40%'
        sx={{ backgroundColor: 'white', borderRadius: 4 }}
        p={4}
        marginLeft={8}
      >
        <Box sx={{ gap: '16px' }}>
          <Box display={'flex'} justifyContent='space-between' width='120px'>
            <img width={60} src={star} />{' '}
            <img width={60} className='pl-2' src={ace} />
            <img width={60} className='px-2' src={pro} />
            <img width={60} src={champ} />
          </Box>

          <Box flex={1} mr={1} mt={4}>
            <Typography fontFamily='Poppins'>Choose Category</Typography>
            <Select
              fullWidth
              displayEmpty
              sx={{ mt: 2, mb: '16px', borderRadius: '8px' }}
              MenuProps={{
                disablePortal: true,
                PaperProps: {
                  style: {
                    zIndex: 1302,
                  },
                },
              }}
              value={badge?.locum_badge_level}
              onChange={(e) =>
                setBadge({
                  ...badge,
                  locum_badge_level: e.target.value,
                })
              }
            >
              <MenuItem disabled value=''>
                <em style={{ color: '#8C8C8C' }}>Select</em>
              </MenuItem>
              <MenuItem value='locum.badge.beginner'>
                Beginner (no badge)
              </MenuItem>
              <MenuItem value='locum.badge.star'>Star</MenuItem>
              <MenuItem value='locum.badge.ace'>Ace</MenuItem>
              <MenuItem value='locum.badge.pro'> Pro</MenuItem>
              <MenuItem value='locum.badge.champ'>Champ</MenuItem>
            </Select>
          </Box>

          <Box flex={1} mr={1} mt={1}>
            <Typography fontFamily='Poppins'>Choose Active</Typography>
            <Select
              fullWidth
              displayEmpty
              sx={{ mt: 2, mb: '16px', borderRadius: '8px' }}
              MenuProps={{
                disablePortal: true,
                PaperProps: {
                  style: {
                    zIndex: 1302,
                  },
                },
              }}
              value={badge.show_locum_badge_level ? 'true' : 'false'}
              onChange={(e) =>
                setBadge((prev) => ({
                  ...prev,
                  show_locum_badge_level: e.target.value === 'true',
                }))
              }
            >
              <MenuItem disabled value=''>
                <em style={{ color: '#8C8C8C' }}>Select</em>
              </MenuItem>
              <MenuItem value='true'>True</MenuItem>
              <MenuItem value='false'>False</MenuItem>
            </Select>
          </Box>
        </Box>
        <Typography mt={2} fontFamily='Poppins' fontSize='16px'>
          *Note:
        </Typography>
        <Box paddingLeft={1} mt={3} display='flex'>
          <Box>
            <Typography fontFamily='Poppins'>Beginner:</Typography>
            <Typography fontFamily='Poppins'>Star:</Typography>
            <Typography fontFamily='Poppins'>Ace:</Typography>
            <Typography fontFamily='Poppins'>Pro:</Typography>
            <Typography fontFamily='Poppins'>Champ:</Typography>
          </Box>
          <Box paddingLeft={1}>
            <Typography color='gray' fontFamily='Poppins'>
              &lt; 350 workhours
            </Typography>
            <Typography color='gray' fontFamily='Poppins'>
              350 workhours
            </Typography>
            <Typography color='gray' fontFamily='Poppins'>
              500 workhours
            </Typography>
            <Typography color='gray' fontFamily='Poppins' paddingLeft={0.2}>
              700 workhours
            </Typography>
            <Typography color='gray' fontFamily='Poppins' paddingLeft={0.3}>
              1000 workhours
            </Typography>
          </Box>
        </Box>
        <Box mt={5} display='flex' justifyContent='end' paddingRight={2}>
          <Button
            sx={{ textTransform: 'none', borderRadius: 2, width: '100px' }}
            mt={5}
            variant='contained'
            className='bg-cyan-600'
            onClick={handleUpdateBadge}
          >
            <Typography fontFamily='Poppins'>Save</Typography>
          </Button>
        </Box>
        <br />
      </Box>
    </Box>
  );
}
