/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
// eslint-disable-next-line import/no-unresolved
import mime from 'browserify-mime';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { mapObjectValsTo } from './mapper';

dayjs.extend(utc);
dayjs.extend(timezone);

export { v1 as uuid } from 'uuid';

export * from './certificate';
export * from './mapper';
export * from './tus';
export * from './hooks';

export const FORGOT_PASSWORD_EMAIL = 'fpe';
export const FORGOT_PASSWORD_RESULT = 'fpr';

export const verifyClinicId = (id, clinicIds) => {
  if (clinicIds.includes(String(id))) {
    return true;
  }
  return false;
};

export const capitalizeWords = (sentence) =>
  sentence
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const dropItemFrom = (obj) => (key) => {
  const { [key]: _, ...rest } = { ...obj };
  return rest;
};

export const getTextWidth = (text, font) => {
  const offScreenElement = document.createElement('span');
  offScreenElement.style.position = 'absolute';
  offScreenElement.style.visibility = 'hidden';
  offScreenElement.style.width = 'auto';
  offScreenElement.style.font = font;
  offScreenElement.textContent = text;

  document.body.appendChild(offScreenElement);
  const width = offScreenElement.offsetWidth;
  document.body.removeChild(offScreenElement);

  return width;
};

export const openLinkInNewTab = (href, options = {}) => {
  const link = document.createElement('a');
  Object.assign(link, {
    ...options,
    style: { display: 'none' },
    target: '_blank',
    rel: 'noopener noreferrer',
    href: href.replace(/%20/g, '+'),
  });
  link.click();
  link.remove();
};

export const openLinkInPopUpWindow = (
  url,
  windowTitle = 'Untitled',
  width = 960,
  height = 540,
  win = window,
) => {
  const left = Number(win.screen.width / 2.12 - width / 2);
  const top = Number(win.screen.height / 3.13 - height / 2);
  const popUpWindow = win.open(
    url,
    '_blank',
    `scrollbars=no,location=no,directories=no,menubar=no,toolbar=no,status=no,width=${width},height=${height},top=${top},left=${left}`,
  );

  popUpWindow.onload = () => {
    popUpWindow.document.title = windowTitle;
  };
  popUpWindow.document.title = windowTitle;

  return false;
};

export const openLinkWithEmbeddedPDF = (pdfUrl) => {
  const iframe = document.createElement('iframe');
  const container = document.createElement('div');
  container.style.position = 'relative';
  container.style.top = 0;
  container.style.bottom = 0;

  iframe.src = pdfUrl;
  iframe.style.width = '100%';
  iframe.style.height = '500px'; // Adjust the height as needed
  iframe.style.zIndex = 9999;
  iframe.style.position = 'absolute';

  container.appendChild(iframe);
  document.body.appendChild(container);
};

export const fallbackCopyTextToClipboard = (text) => {
  let textArea = null;

  if (text) {
    textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.display = 'none';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  }

  try {
    const isSuccess = document.execCommand('copy');
    const msg = isSuccess ? 'successful' : 'unsuccessful';
    console.log(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    throw new Error(`Fallback: Oops, unable to copy ${err}`);
  }

  if (text && textArea) document.body.removeChild(textArea);
};

export const copyTextToClipboard = async (text) => {
  if (!navigator.clipboard) {
    // eslint-disable-next-line no-unneeded-ternary
    fallbackCopyTextToClipboard(text ? text : undefined);
    return;
  }
  try {
    // eslint-disable-next-line no-unneeded-ternary
    navigator.clipboard.writeText(text ? text : '');
  } catch (e) {
    throw new Error(`Async: Could not copy text: ${e}`);
  }
};

export const setTimeoutAsync = async (cb, milliseconds) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(cb());
    }, milliseconds);
  });
};

export const noop = () => {};

export function* range(start, end, step) {
  while (start < end) {
    yield start;
    // eslint-disable-next-line no-param-reassign
    start += step;
  }
}

export const fileNameFromUrl = (url) => {
  // eslint-disable-next-line no-useless-escape
  const matches = url.match(/\/([^\/?#]+)[^\/]*$/);
  if (matches.length > 1) {
    return matches[1];
  }
  return null;
};

export const createFileFromImagePath = (imagePath) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);

      canvas.toBlob((blob) => {
        if (blob) {
          const fileName = fileNameFromUrl(imagePath) || 'file';
          const file = new File([blob], fileName, { type: blob.type });
          resolve(file);
        } else {
          reject(new Error('Failed to create File object.'));
        }
      }, img.type);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = imagePath;
  });
};

export const downloadAsFile = async (url) => {
  try {
    const response = await axios.get(url, { responseType: 'blob' });

    // Create a File object from the blob response
    const file = new File(
      [response.data],
      fileNameFromUrl(url) || 'downloadedFile',
      {
        type: response.headers['content-type'],
      },
    );

    return file;
  } catch (error) {
    console.error('Error downloading file:', error);
    return null;
  }
};

export const downloadFile = (file, fileName, replace = false) => {
  const fileExtension = mime.extension(file.type);
  const fullFileName = replace ? fileName : `${fileName}.${fileExtension}`;
  const href = URL.createObjectURL(file);
  const link = document.createElement('a');

  link.href = href;
  link.setAttribute('download', fullFileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(href);
};

export const getSparsedRandomValue = (
  min = 0,
  max = 1000,
  previousValue = 0,
  minGap = 10,
  maxGap = 900,
) => {
  const gap = Math.random() * (maxGap - minGap) + minGap;
  const offset = Math.random() * gap - gap / 2;
  const nextValue = previousValue + offset;
  const clampedNextValue = Math.min(Math.max(nextValue, min), max);

  return clampedNextValue;
};

export const blobToFile = (
  blob,
  name = 'file',
  type,
  lastModified = new Date().getTime(),
  encoding = 'utf-8',
) => new File([blob], name, { type, lastModified }, encoding);

export const fileToStringDataAsync = async (file) => {
  const blob = await fileToByteArrayAsync(file);
  const encodedBlob = base64.fromByteArray(new Uint8Array(blob));
  return {
    name: file.name,
    type: file.type,
    lastModified: file.lastModified,
    base64data: encodedBlob,
  };
};

export const swapObject = (obj) =>
  Object.entries({ ...obj }).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});

export const formatDateWithTimezone = (
  date,
  time,
  timeZone = 'Asia/Singapore',
) => {
  const dateTime = dayjs(date)
    .hour(dayjs(time).hour())
    .minute(dayjs(time).minute());
  return dateTime.tz(timeZone);
};

const formatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const formatShortDate = (date) => {
  return new Date(date)
    .toLocaleString('en-AU', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Singapore',
    })
    .replace('am', 'AM')
    .replace('pm', 'PM');
};

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function formatRole(role) {
  if (!role) return 'No role';

  return role
    .split(/[._]/)
    .map((word) => capitalize(word))
    .join(' ');
}
